import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Cookies from "universal-cookie";
import { Mountain_Default } from "../common/functions";
import timezones from "../common/timezones.json";
import moment from "moment";
import Popup from 'reactjs-popup';
const cookies = new Cookies();

var zone = moment.tz.guess();
var abbr = moment.tz(zone).format("z");
var diff = moment.tz(zone).format("Z");
const Timezones = timezones.Timezones;
let TestZone = Timezones.find(_ => _.Abbreviation === abbr && _.Zone === diff)
let selectedZones = TestZone ? TestZone : Mountain_Default
let CURRENT_TIME_ZONE = selectedZones;
function Header(props) {
	const dispatch = useDispatch();

	// const history = useHistory();
	const [timezoneShow, setTimezoneShow] = useState(false)
	const user = useSelector((e) => e.User);
	const ProjectInfo = useSelector((e) => e.ProjectInfo);

	const logout = (e) => {
		dispatch({ type: 'USER', payload: {} })
		dispatch({ type: 'FORM_DATA', payload: {} })
		dispatch({ type: 'REF_CODE', payload: '' })
		cookies.remove(!['prod1', 'prod2', 'prod3'].some(env => process.env.REACT_APP_ENVIRONMENT.includes(env)) ? process.env.REACT_APP_ENVIRONMENT + "PAFUser" : "PAFUser", {
			domain: process.env.REACT_APP_COOKIE_DOMAIN,
			path: "/"
		});
		cookies.remove(!['prod1', 'prod2', 'prod3'].some(env => process.env.REACT_APP_ENVIRONMENT.includes(env)) ? process.env.REACT_APP_ENVIRONMENT + "PAFProjectId" : "PAFProjectId", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
		localStorage.clear()
	};

	const getSecondZone = () => {
		let timeZone = JSON.parse(localStorage.getItem('TIMEZONE')) ? JSON.parse(localStorage.getItem('TIMEZONE')).Abbreviation : abbr;

		if (timeZone === Mountain_Default.Abbreviation) {
			let mountainZone = Timezones.find(_ => _.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
			localStorage.setItem('TIMEZONE', JSON.stringify(mountainZone))
			return selectedZones.Name
		}
		else {
			let mountainZone = Timezones.find(_ => _.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
			return mountainZone.Name
		}
	}

	const getZones = (TYPE, IS_FIRST) => {

		if (IS_FIRST) {
			let timeZone = JSON.parse(localStorage.getItem('TIMEZONE')) ? JSON.parse(localStorage.getItem('TIMEZONE')).Abbreviation : abbr;
			TYPE = timeZone !== Mountain_Default.Abbreviation ? 'ALL' : timeZone
		}


		if (TYPE === 'ALL') {
			if (selectedZones) {
				localStorage.setItem('TIMEZONE', JSON.stringify(selectedZones))
				return selectedZones.Name
			}
			else {
				let mountainZone = Timezones.find(_ => _.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
				localStorage.setItem('TIMEZONE', JSON.stringify(mountainZone))
				return mountainZone.Name
			}
		}
		else {

			let mountainZone = Timezones.find(_ => _.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
			return mountainZone.Name
		}
	}


	const changeTimeZone = (timeZone) => {
		if (timeZone === Mountain_Default.Abbreviation) {
			setState({
				NewTimezone: getZones(Mountain_Default.Abbreviation),
				SecondTimezone: getZones('ALL')
			})
			let mountainZone = Timezones.find(_ => _.Abbreviation === Mountain_Default.Abbreviation && _.Zone === Mountain_Default.Zone)
			localStorage.setItem('TIMEZONE', JSON.stringify(mountainZone))
		}
		else {
			setState({
				NewTimezone: getZones('ALL'),
				SecondTimezone: 'Mountain Standard Time'
			})
		}

		window.location.reload();
	}
	const [state, setState] = useState({
		NewTimezone: getZones('ALL', true),
		SecondTimezone: getSecondZone(),
	})
	const ShowTimeMenu = () => {
			 setTimezoneShow(!timezoneShow)
	}

	return (
		<div className="sd_header_section header_slider_section sd_relative">
			<header>
				<div className="sd_large_container">
					<div className="sd_header_sec_wpr">
						<div className="sd_header_wpr sd_flex">
							{/* Logo */}
							<div className="sd_header_logo">
								<Link to="/" className="sd_desktop_logo">
									<img src={'/images/sundance_logo.svg'} alt="logo" />
								</Link>
							</div>
							{ window.location.pathname.includes('shared-page') || window.location.pathname.includes('shared-page') ?"" :
								user && Object.keys(user).length > 0 &&
								<div className="sd_user_profile_user_right sd_flex sd_aligncenter">
									{ProjectInfo?.title?.length > 15 ? (
									<Popup
									trigger={(open) => (
										<span className="button">Post Acceptance Form:{ProjectInfo?.article + "  " + ProjectInfo?.title}</span>
									)}
									position="bottom center"
									on={["hover", "focus"]}
									closeOnDocumentClick
									>
									<span>{ProjectInfo?.article + "  " + ProjectInfo?.title}</span>
									</Popup>
									) : (
										<p className="sd_paf_txt"><span>Post Acceptance Form: </span>{ProjectInfo?.article + "  " + ProjectInfo?.title}</p>
									)}
									{/* <p>Post Acceptance Form: {ProjectInfo?.article +'  '+ ProjectInfo?.title} </p> */}
									<div className="sd_user_profile_user sd_flex sd_aligncenter">
										<Link className="sd_user_profile sd_flex sd_aligncenter" >

											{/* <div className="sd_user_profile_image">
												<img src="/images/user.svg" alt="User name" title="User name" />
											</div> */}
											<p title={`${ProjectInfo?.firstName || user?.fname} ${ProjectInfo?.lastName || user?.lname}`}> Hi, {ProjectInfo?.firstName || user?.fname} </p>
										</Link>
										<div className="sd_user_profile_menus sd_user_profile_m_menu sd_hidden">
											<ul>
												<li><Link onClick={() => logout()}>Sign out</Link></li>
											</ul>
										</div>
									</div>
								</div>
						 }
						</div>
					</div>
				</div>
				{/* <div className="header_timezone sd_flex sd_aligncenter sd_flexwrap" onClick={ShowTimeMenu}>
					<span className="sd_textuppercase label_timezone">TIMEZONE</span>
					<div className={`sd_p_cursor sd_flex sd_aligncenter select_timezone ${timezoneShow ? 'active': ''}`} >
						<input name='timezoneSelect' disabled value={state.NewTimezone}/> 

						{
							CURRENT_TIME_ZONE.Abbreviation !== Mountain_Default.Abbreviation &&

							<>
								<span className="sd_p_cursor"><svg width="11" height="6" viewBox="0 0 11 6" fill="none"><path d="M1.29285 0.714355L5.75626 5.00007L10.0071 0.918437" stroke="white" stroke-width="1.218"/></svg></span>
								<ul>
									<li onClick={()=>{ changeTimeZone(state.NewTimezone === Mountain_Default.Name ? moment.tz.guess() : Mountain_Default.Abbreviation) }}>{state.SecondTimezone}</li>
								</ul>
							</>
						}
					</div>
				</div> */}
			</header>
		</div>
	)
}

export default Header