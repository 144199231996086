import React from 'react'
import { useSelector } from 'react-redux';

const Banner = () => {
    const headerData = useSelector(state => state.bannerContent || JSON.parse(localStorage.getItem("header")));
    return (
        <div className="sd_banner sd_paf_banner">
            <div className="sd_container">
                <div className="sd_aligncenter sd_banner_inner">
                    <div className="sd_banner_inner_title">
                        <h1 className="">Artist Portal</h1>
                    </div>
                    <div className="sd_banner_inner_detail" dangerouslySetInnerHTML={{ __html: headerData }}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner;