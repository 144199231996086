import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'
import NotFound from './pages/auth/NotFound';
import SignIn from './pages/auth/SignIn'
import CreateAccount from './pages/auth/CreateAccount'
import ForgotPassword from './pages/auth/ForgotPassword'
import ResetPassword from './pages/auth/ResetPassword'
import FilmInformation from './pages/auth/FilmInformation'
import { useDispatch, useSelector } from "react-redux";
import PrevStep1 from "./PAF/ShareWithTeam/PrevStep1";
import PrevStep2 from "./PAF/ShareWithTeam/PrevStep2";
import PrevStep3 from "./PAF/ShareWithTeam/PrevStep3";
import PrevStep4 from "./PAF/ShareWithTeam/PrevStep4";
import PrevStep5 from "./PAF/ShareWithTeam/PrevStep5";
import PrevStep6 from "./PAF/ShareWithTeam/PrevStep6";
import PrevStep7 from "./PAF/ShareWithTeam/PrevStep7";
import PrevStep8 from "./PAF/ShareWithTeam/PrevStep8";
import PrevStep9 from "./PAF/ShareWithTeam/PrevStep9";
import Preview from "./PAF/Components/Preview";
import Cookies from "universal-cookie";

// import Step1 from "./PAF/Components/Step1";
// import Step2 from "./PAF/Components/Step2";
// import Step3 from "./PAF/Components/Step3";
// import Step4 from "./PAF/Components/Step4";
// import Step5 from "./PAF/Components/Step5";
// import Step6 from "./PAF/Components/Step6";
// import Step7 from "./PAF/Components/Step7";
// import Step8 from "./PAF/Components/Step8";
// import Step9 from "./PAF/Components/Step9";
import { routeLocater } from "./common/functions";

// Narrative
import NarrativeStep1 from './PAF/Components/Narrative/Step1';
import NarrativeStep2 from './PAF/Components/Narrative/Step2';
import NarrativeStep3 from './PAF/Components/Narrative/Step3';
import NarrativeStep4 from './PAF/Components/Narrative/Step4';
import NarrativeStep5 from './PAF/Components/Narrative/Step5';
import NarrativeStep6 from './PAF/Components/Narrative/Step6';
import NarrativeStep7 from './PAF/Components/Narrative/Step7';
import NarrativeStep8 from './PAF/Components/Narrative/Step8';
import NarrativeStep9 from './PAF/Components/Narrative/Step9';


// indie series
import IndieStep1 from './PAF/Components/Indie/Step1';
import IndieStep2 from './PAF/Components/Indie/Step2';
import IndieStep3 from './PAF/Components/Indie/Step3';
import IndieStep4 from './PAF/Components/Indie/Step4';
import IndieStep5 from './PAF/Components/Indie/Step5';
import IndieStep6 from './PAF/Components/Indie/Step6';
import IndieStep7 from './PAF/Components/Indie/Step7';
import IndieStep8 from './PAF/Components/Indie/Step8';
import IndieStep9 from './PAF/Components/Indie/Step9';

/* new frontier */
import NFStep1 from './PAF/Components/New-Frontier/Step1';
import NFStep2 from './PAF/Components/New-Frontier/Step2';
import NFStep3 from './PAF/Components/New-Frontier/Step3';
import NFStep4 from './PAF/Components/New-Frontier/Step4';
import NFStep5 from './PAF/Components/New-Frontier/Step5';
import NFStep6 from './PAF/Components/New-Frontier/Step6';
import NFStep7 from './PAF/Components/New-Frontier/Step7';
import NFStep8 from './PAF/Components/New-Frontier/Step8';
import NFStep9 from './PAF/Components/New-Frontier/Step9';

// Documentary
import DocStep1 from './PAF/Components/Documentary/Step1';
import DocStep2 from './PAF/Components/Documentary/Step2';
import DocStep3 from './PAF/Components/Documentary/Step3';
import DocStep4 from './PAF/Components/Documentary/Step4';
import DocStep5 from './PAF/Components/Documentary/Step5';
import DocStep6 from './PAF/Components/Documentary/Step6';
import DocStep7 from './PAF/Components/Documentary/Step7';
import DocStep8 from './PAF/Components/Documentary/Step8';
import DocStep9 from './PAF/Components/Documentary/Step9';




const Routers = () => {


	const history = useHistory()
	const ProjectInfo = useSelector(e => e?.ProjectInfo);
	const pafType = useSelector(e => e?.pafType);
	const SharedPafType = useSelector(e => e?.SharedPafType);
	
	let SaveNumber = ProjectInfo && ProjectInfo?.savePageNo
	let CompleteNumber = ProjectInfo && ProjectInfo?.completedStepNo
	useEffect(() => {
		if (window.location.href.includes('step') && !window.location.href.includes('shared-page')) {
			let url = window.location.pathname
			let CurrentPage = url.match(/\d+/)
			CurrentPage = parseInt(CurrentPage ? CurrentPage[0] : '')

			if (CurrentPage > CompleteNumber) {
				if (CurrentPage !== SaveNumber) {

					history.push(`${routeLocater(pafType)}-page${CompleteNumber === 9 ? 1 : CompleteNumber === 0 ? 1 : CompleteNumber + 1}`)
				}
			}
		}
		// eslint-disable-next-line
	}, [])
	const cookies = new Cookies();
	const dispatch = useDispatch();
	let url = window.location.href
	if (url.includes('create-account')) {
		dispatch({ type: 'USER', payload: {} })
		cookies.remove(!['prod1', 'prod2', 'prod3'].some(env => process.env.REACT_APP_ENVIRONMENT.includes(env)) ? process.env.REACT_APP_ENVIRONMENT + "PAFUser" : "PAFUser", {
			domain: process.env.REACT_APP_COOKIE_DOMAIN,
			path: "/"
		});
		localStorage.clear()
	}
	return (
		<Switch>

			<PublicRoute exact path={`/`} component={SignIn} />
			<PublicRoute exact path={`/create-account/:projectId?`} component={CreateAccount} />
			<PublicRoute exact path={`/forgot-password`} component={ForgotPassword} />
			<Route exact path={`/reset-password/:ResetVerification?`} component={ResetPassword} />
			<PrivateRoute exact path={`/film-information`} component={FilmInformation} />
			{/* 
{/* 
			{/* 
			<PrivateRoute exact path={`/step1`} component={pafType === 'Indie Series/Special Events' ? IndieStep1 : Step1} />
			<PrivateRoute exact path={`/step2`} component={Step2} />
			<PrivateRoute exact path={`/step3`} component={Step3} />
			<PrivateRoute exact path={`/Step4`} component={Step4} />
			<PrivateRoute exact path={`/Step5`} component={Step5} />
			<PrivateRoute exact path={`/Step6`} component={Step6} />
			<PrivateRoute exact path={`/Step7`} component={Step7} />
			<PrivateRoute exact path={`/step8`} component={Step8} /> */}
			{/* <PrivateRoute exact path={`/newFrontier-page7`} component={Step8} />
			<PrivateRoute exact path={`/newFrontier-page8`} component={Step9} /> */}
			{/* <PrivateRoute exact path={`/step9`} component={Step9} /> */}

			{/* Narrative */}
			<PrivateRoute exact path={`/narrative-page1`} component={NarrativeStep1} />
			<PrivateRoute exact path={`/narrative-page2`} component={NarrativeStep2} />
			<PrivateRoute exact path={`/narrative-page3`} component={NarrativeStep3} />
			<PrivateRoute exact path={`/narrative-page4`} component={NarrativeStep4} />
			<PrivateRoute exact path={`/narrative-page5`} component={NarrativeStep5} />
			<PrivateRoute exact path={`/narrative-page6`} component={NarrativeStep6} />
			<PrivateRoute exact path={`/narrative-page7`} component={NarrativeStep7} />
			<PrivateRoute exact path={`/narrative-page8`} component={NarrativeStep8} />
			<PrivateRoute exact path={`/narrative-page9`} component={NarrativeStep9} />


			{/* indie series */}
			<PrivateRoute exact path={`/indie-page1`} component={IndieStep1} />
			<PrivateRoute exact path={`/indie-page2`} component={IndieStep2} />
			<PrivateRoute exact path={`/indie-page3`} component={IndieStep3} />
			<PrivateRoute exact path={`/indie-page4`} component={IndieStep4} />
			<PrivateRoute exact path={`/indie-page5`} component={IndieStep5} />
			<PrivateRoute exact path={`/indie-page6`} component={IndieStep6} />
			<PrivateRoute exact path={`/indie-page7`} component={IndieStep7} />
			<PrivateRoute exact path={`/indie-page8`} component={IndieStep8} />
			<PrivateRoute exact path={`/indie-page9`} component={IndieStep9} />


			{/* new frontier */}
			<PrivateRoute exact path={`/newFrontier-page1`} component={NFStep1} />
			<PrivateRoute exact path={`/newFrontier-page2`} component={NFStep2} />
			<PrivateRoute exact path={`/newFrontier-page3`} component={NFStep3} />
			<PrivateRoute exact path={`/newFrontier-page4`} component={NFStep4} />
			<PrivateRoute exact path={`/newFrontier-page5`} component={NFStep5} />
			<PrivateRoute exact path={`/newFrontier-page6`} component={NFStep6} />
			<PrivateRoute exact path={`/newFrontier-page7`} component={NFStep8} />
			<PrivateRoute exact path={`/newFrontier-page8`} component={NFStep9} />
			{/* <PrivateRoute exact path={`/newFrontier-page9`} component={NFStep9} /> */}

			{/* documentary */}
			<PrivateRoute exact path={`/documentary-page1`} component={DocStep1} />
			<PrivateRoute exact path={`/documentary-page2`} component={DocStep2} />
			<PrivateRoute exact path={`/documentary-page3`} component={DocStep3} />
			<PrivateRoute exact path={`/documentary-page4`} component={DocStep4} />
			<PrivateRoute exact path={`/documentary-page5`} component={DocStep5} />
			<PrivateRoute exact path={`/documentary-page6`} component={DocStep6} />
			<PrivateRoute exact path={`/documentary-page7`} component={DocStep7} />
			<PrivateRoute exact path={`/documentary-page8`} component={DocStep8} />
			<PrivateRoute exact path={`/documentary-page9`} component={DocStep9} />

			{/*Preview pages routes */}
			<Route exact path={`/:ProjectId/shared-page1/`} component={PrevStep1} />
			<Route exact path={`/:ProjectId/shared-page2/`} component={PrevStep2} />
			<Route exact path={`/:ProjectId/shared-page3/`} component={PrevStep3} />
			<Route exact path={`/:ProjectId/shared-page4/`} component={PrevStep4} />
			<Route exact path={`/:ProjectId/shared-page5/`} component={PrevStep5} />
			<Route exact path={`/:ProjectId/shared-page6/`} component={PrevStep6} />
			<Route exact path={`/:ProjectId/shared-page7/`} component={SharedPafType === 'New Frontier' ? PrevStep8 : PrevStep7} />
			<Route exact path={`/:ProjectId/shared-page8/`} component={SharedPafType === 'New Frontier' ? PrevStep9 : PrevStep8} />
			<Route exact path={`/:ProjectId/shared-page9/`} component={PrevStep9} />
			<Route exact path={`/Preview/:projectId?`} component={Preview} />
			<Route path="*" component={NotFound} />
		</Switch>
	);

}

export default Routers;
