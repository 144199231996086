import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getCookie } from './common/functions';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    return (
        <Route {...rest} render={props => {
            if (!getCookie('PAFUser')) { return <Redirect to={{ pathname: '/', state: { from: props.location } }} /> }
            return <Component {...props} />
        }} />
    );
};

export { PrivateRoute }